import React from "react";
import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 28px;
  font-family: "Opificio Bold", sans-serif;
  margin-top: 50px;
  margin-bottom: 48px;

  @media screen and (max-width: 767px) {
    font-size: 18px;
    margin-top: 48px;
    margin-bottom: 0px;
  }
`;

const InfoHeader = styled.p`
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: bold;

  span {
    display: block;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;
const Info = styled.p`
  font-size: 18px;
  margin-bottom: 0px;
  text-align: justify;

  span {
    display: block;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

const PrivacyPolicy = () => {
  return (
    <>
      <Box>
        <StaticImage
          layout="fullWidth"
          objectFit="contain"
          src="../../images/header-bg.png"
          alt="beauty-tips-bg"
          loading="lazy"
        />
        <Container
          maxW={{ lg: "container.md", xl: "container.xl" }}
          pt={8}
          pb={{ base: 10, md: 20, lg: 40 }}>
          <SimpleGrid
            mb={{ base: 8, md: -8 }}
            columns={{ base: 1, lg: 2 }}
            spacing={{ base: 1, lg: 10 }}>
            <Box textAlign="center">
              <Box
                position="relative"
                bottom={{ base: "80px", md: "120px", lg: "140px", xl: "160px" }}>
                <StaticImage
                  layout="constrained"
                  objectFit="contain"
                  src="../../images/header-product.png"
                  alt="beauty-tips-product"
                  loading="lazy"
                />
              </Box>
            </Box>
            <Box mt={-16} p={{ base: 4, lg: 0 }}>
              <Title>KEBIJAKAN PRIVASI</Title>
              <Info>
                Kebijakan Privasi ini menjelaskan bagaimana PT Varcos Citra Internasional menangani
                data pribadi yang kami kumpulkan secara online (misalnya melalui media sosial kami,
                situs web, atau situs web perdagangan merek kami) dan offline (misalnya pengambilan
                data di lokasi ritel dan kegiatan promosi langsung) yang selanjutnya akan disebut
                “Layanan”
              </Info>
              <br />
              <Info>
                Kebijakan Privasi ini menjelaskan jenis data pribadi yang kami kumpulkan dan proses,
                bagaimana kami dapat menggunakan dan berbagi data tersebut, serta pilihan yang
                tersedia untuk Anda sehubungan dengan penanganan kami terhadap data Anda.
              </Info>
              <br />
              <InfoHeader>Apa Yang Kami Kumpulkan</InfoHeader>
              <br />
              <InfoHeader>Informasi yang kami kumpulkan langsung dari Anda</InfoHeader>
              <Info>
                Kami mengumpulkan informasi yang Anda berikan langsung kepada kami, seperti ketika
                Anda mendaftarkan akun di situs web, situs web perdagangan merek kami, mendaftar ke
                buletin kami, menyatakan minat pada voucher kami, atau berinteraksi dengan kami di
                media sosial. Jenis data pribadi yang dapat kami kumpulkan langsung dari Anda
                meliputi:
                <Box mt={{ base: 4 }} ml={{ base: 8 }}>
                  <ul>
                    <li>
                      Informasi kontak, seperti nama, tanggal lahir, alamat email, nomor ponsel,
                      akun Instagram, dan profesi Anda;
                    </li>
                    <li>Alamat, seperti nama jalan, area, kota, provinsi, dan negara.</li>
                    <li>
                      Informasi kulit, seperti jenis kulit, masalah kulit, dan warna kulit Anda;
                    </li>
                    <li>
                      Informasi produk, seperti produk make-up yang Anda miliki dan bagaimana Anda
                      bisa tahu produk kami;
                    </li>
                    <li>Informasi lain yang Anda pilih untuk diberikan.</li>
                  </ul>
                </Box>
              </Info>
              <br />
              <InfoHeader>Informasi tentang penggunaan Anda atas Layanan kami</InfoHeader>
              <Info>
                Kami mengumpulkan informasi tentang penggunaan Layanan yang Anda lakukan, seperti:
                <br />
                Mengakses dan mengisikan data diri pada fitur kuis, game, review ataupun promosi
                apapun pada platform kami.
                <br />
                Jika Anda menggunakan fitur berbagi kami untuk menginformasikan produk ke
                teman-teman Anda, kami akan bisa mengumpulkan informasi tersebut.
              </Info>
              <br />
              <InfoHeader>Informasi yang kami kumpulkan dari sumber lain</InfoHeader>
              <Info>
                Saat Anda mengakses Layanan melalui jejaring sosial, kami mengumpulkan informasi
                dari jejaring sosial sesuai dengan pengaturan Anda di jejaring sosial. Jika Anda
                berinteraksi dengan kami di media sosial, kami akan mengumpulkan informasi tentang
                interaksi tersebut. Informasi yang kami kumpulkan meliputi nama Anda, gambar,
                pegangan media sosial atau nama pengguna, dan alamat email yang sesuai dengan media
                sosial. Kami juga dapat mengumpulkan informasi tentang Anda dari pihak ketiga,
                termasuk sumber yang tersedia untuk umum atau teman yang membagikan produk kami di
                saluran berbagi mereka.
              </Info>
              <br />
              <InfoHeader>Informasi yang kami kumpulkan dengan cara otomatis</InfoHeader>
              <Info>
                Ketika Anda mengunjungi situs kami, kami mengumpulkan informasi tertentu secara
                otomatis. Untuk mengumpulkan informasi ini, kami dapat menggunakan cookie dan
                teknologi serupa. “Cookie” adalah file teks yang dikirim situs web ke komputer
                pengunjung atau perangkat lain yang terhubung ke internet untuk secara unik
                mengidentifikasi browser pengunjung atau untuk menyimpan informasi atau pengaturan
                di browser. Kami juga dapat mengumpulkan informasi tentang aktivitas online Anda
                dari waktu ke waktu dan di situs web pihak ketiga. Informasi yang kami kumpulkan
                secara otomatis dapat mencakup:
                <Box mt={{ base: 4 }} ml={{ base: 8 }}>
                  <ul>
                    <li>URL yang merujuk pengunjung ke situs web kami;</li>
                    <li>Istilah pencarian yang digunakan untuk menjangkau situs web kami;</li>
                    <li>
                      Detail tentang perangkat yang digunakan untuk mengakses situs web kami
                      (seperti: alamat IP, informasi browser, informasi perangkat, dan informasi
                      sistem operasi);
                    </li>
                    <li>
                      Detail tentang interaksi Anda dengan situs web kami (seperti: tanggal, waktu,
                      lama mengakses situs kami, halaman spesifik yang diakses selama kunjungan Anda
                      ke situs web kami, dan email pemasaran yang Anda buka); dan
                    </li>
                    <li>
                      Informasi penggunaan (seperti: jumlah dan frekuensi pengunjung ke situs web
                      kami).
                    </li>
                  </ul>
                </Box>
                <br />
                Kami dapat mengaitkan informasi ini dengan buletin Anda jika Anda berlangganan,
                perangkat yang Anda gunakan untuk terhubung ke Layanan kami, dan email atau akun
                media sosial yang Anda gunakan untuk terhubung dengan kami.
              </Info>
              <br />
              <InfoHeader>
                Layanan Periklanan dan Analitik yang Disediakan oleh Pihak Ketiga
              </InfoHeader>
              <Info>
                Kami dapat mengizinkan orang lain untuk menyediakan layanan analitik dan menayangkan
                iklan atas nama kami di internet dan dalam aplikasi seluler. Entitas ini dapat
                menggunakan cookie dan teknologi lainnya untuk mengumpulkan informasi tentang
                penggunaan Layanan oleh Anda dan situs web dan aplikasi lainnya, misalnya: alamat
                IP, browser web, informasi jaringan seluler, halaman yang dilihat, halaman, waktu
                yang dihabiskan pada halaman atau dalam aplikasi, dan tautan yang diakses. Informasi
                ini dapat digunakan oleh kami; dan penggunaannya tetapi tidak terbatas pada
                menganalisis dan melacak data, tapi juga untuk menentukan popularitas produk atau
                konten tertentu, mengirimkan iklan dan konten yang ditargetkan untuk minat Anda di
                Layanan kami dan situs web lain, dan memiliki pemahaman yang lebih baik aktivitas
                online Anda.
              </Info>
              <br />
              <InfoHeader>Bagaimana Kami Menggunakan Informasi Anda</InfoHeader>
              <Info>
                Kami dapat menggunakan informasi yang kami kumpulkan untuk mengirimkan produk yang
                Anda beli di situs perdagangan merek resmi kami, meningkatkan pengalaman Anda saat
                menjelajahi situs web kami, menyesuaikan buletin langganan Anda dan juga interaksi
                kami dengan Anda, serta untuk menyediakan, memelihara, dan meningkatkan pelayanan
                kami. Kami juga dapat menggunakan informasi yang kami kumpulkan untuk:
                <Box mt={{ base: 4 }} ml={{ base: 8 }}>
                  <ul>
                    <li>
                      Berkomunikasi dengan Anda tentang Layanan kami, termasuk untuk memberitahu
                      Anda tentang produk dan layanan yang mungkin menarik bagi Anda;
                    </li>
                    <li>
                      Memfasilitasi keterlibatan Anda dengan Layanan, termasuk memungkinkan Anda
                      untuk mengirim komentar dan ulasan produk;
                    </li>
                    <li>
                      Memberi tahu Anda tentang tanggal peluncuran produk baru kami, informasi
                      produk baru, dan informasi terkait merek;
                    </li>
                    <li>Memberi penawaran promosi dan giveaway; dan</li>
                    <li>Melakukan atau mengelola survei dan riset pasar lainnya.</li>
                  </ul>
                </Box>
              </Info>
              <br />
              <InfoHeader>Bagaimana Kami dapat Membagi Informasi Anda</InfoHeader>
              <Info>
                Kami dapat membagikan informasi yang kami kumpulkan ke:
                <Box mt={{ base: 4 }} ml={{ base: 8 }}>
                  <ul>
                    <li>
                      Dengan mitra terpilih sebagai bagian dari promosi yang disponsori bersama;
                    </li>
                    <li>
                      Untuk mematuhi kewajiban hukum atau peraturan, melindungi, dan membela hak
                      atau properti kami, melindungi keselamatan pelanggan dan pengguna situs web
                      kami atau publik, dan melindungi terhadap tanggung jawab hukum;
                    </li>
                    <li>
                      Sehubungan dengan, atau selama proses negosiasi, merger, penjualan aset
                      perusahaan, pembiayaan, atau akuisisi semua atau sebagian dari bisnis kami
                      oleh perusahaan lain;
                    </li>
                    <li>
                      Antara dan di antara afiliasi kami saat ini dan yang akan datang, anak
                      perusahaan, dan perusahaan lain di bawah kendali dan kepemilikan yang sama;
                      dan
                    </li>
                  </ul>
                </Box>
                <br />
                Saat Anda memberikan ulasan produk atau konten lain, konten itu akan disortir oleh
                tim kami dan ulasan produk yang dipilih akan diposkan secara publik. Pengunjung
                situs web lain mungkin dapat melihat nama Anda atau informasi lain tentang Anda yang
                Anda poskan, seperti akun media sosial atau nama pengguna Anda.
              </Info>
              <br />
              <InfoHeader>Hak Anda</InfoHeader>
              <br />
              <InfoHeader>Memilih Keluar dari Buletin</InfoHeader>
              <Info>
                Anda dapat berhenti berlangganan buletin kami kapan saja dengan mengikuti instruksi
                yang termasuk dalam surel tersebut. Jika Anda memilih untuk tidak menerima
                komunikasi semacam itu, perhatikan bahwa kami dapat terus mengirimi Anda email
                non-promosi (Seperti email tentang perubahan dalam ketentuan hukum atau kebijakan
                privasi kami).
              </Info>
              <br />
              <InfoHeader>Membatasi Cookie</InfoHeader>
              <Info>
                Sebagian besar browser web diatur untuk menerima cookie secara default. Anda
                biasanya dapat memilih untuk mengatur browser Anda untuk menghapus atau menolak
                cookie browser. Harap perhatikan bahwa jika Anda memilih untuk menghapus atau
                menolak cookie, ini dapat mempengaruhi ketersediaan dan fungsionalitas situs web
                kami.
              </Info>
              <br />
              <InfoHeader>Anak-anak</InfoHeader>
              <Info>
                Layanan kami tidak dirancang untuk anak-anak dan tidak secara sengaja mengumpulkan
                data pribadi dari anak-anak (di bawah 12 tahun). Jika Anda memiliki alasan untuk
                meyakini bahwa seorang anak telah memberikan data pribadi kepada kami, silakan
                hubungi kami di hello@itsomgbeauty.com dan kami akan memeriksa kembali fakta-fakta
                dan menghapus informasi itu dari basis data kami jika itu terbukti sebagai data
                anak-anak.
              </Info>
              <br />
              <InfoHeader>Tautan ke Situs Web Lain dan Konten Pihak Ketiga</InfoHeader>
              <Info>
                Kami dapat menyediakan tautan ke situs web pihak ketiga, layanan, dan aplikasi,
                seperti Instagram dan situs web perdagangan lainnya, yang tidak dioperasikan atau
                dikendalikan oleh kami. Kebijakan Privasi ini tidak berlaku untuk layanan pihak
                ketiga, dan kami tidak dapat bertanggung jawab atas konten, kebijakan privasi, atau
                praktik layanan pihak ketiga. Kami mendorong Anda untuk meninjau kebijakan privasi
                layanan pihak ketiga mana pun sebelum memberikan informasi apapun kepada atau
                melalui mereka. Interaksi Anda dengan fitur-fitur dari pihak ketiga ini diatur oleh
                kebijakan privasi layanan pihak ketiga yang menyediakan fitur tersebut.
              </Info>
              <br />
              <InfoHeader>Retensi Data</InfoHeader>
              <Info>
                Periode penyimpanan kami untuk data pribadi didasarkan pada kebutuhan bisnis dan
                persyaratan hukum. Kami menyimpan data pribadi selama diperlukan untuk keperluan
                pemrosesan yang datanya dikumpulkan, dan tujuan terkait lainnya yang diizinkan.
              </Info>
              <br />
              <InfoHeader>Perubahan terhadap Kebijakan Privasi Kami</InfoHeader>
              <Info>
                Kami dapat mengubah Kebijakan Privasi ini dari waktu ke waktu. Jika kami
                melakukannya, kami akan memposting kebijakan yang diperbarui di situs kami dan akan
                menunjukkan kapan Kebijakan Privasi terakhir direvisi. Kami juga akan mengirimkan
                pemberitahuan email kepada Anda bahwa Kebijakan Privasi kami baru-baru ini berubah.
                Anda harus secara berkala meninjau Kebijakan Privasi kami saat ini untuk tetap
                mendapat informasi tentang praktik data pribadi kami.
              </Info>
              <br />
              <InfoHeader>Menghubungi Kami</InfoHeader>
              <Info>
                Jika Anda memiliki pertanyaan atau masalah mengenai Kebijakan Privasi ini, atau
                ingin memperbarui preferensi atau informasi kami tentang Anda, silakan hubungi kami
                di alamat yang disediakan di bawah ini. Kami akan mengambil langkah wajar dan segera
                untuk mengakomodasi permintaan Anda.
              </Info>
              <br />
              <InfoHeader>PT VARCOS CITRA INTERNASIONAL</InfoHeader>
              <Info>
                Jalan Industri Raya IV Blok AH No. 1 Bunder Cikupa
                <br />
                Tangerang, Indonesia
                <br />
                <br />
                E-mail:
                <br />
                hello@itsomgbeauty.com
                <br />
                <br />
                *(PT Varcos Citra Internasional adalah pemilik dan produsen dari produk OMG Oh My
                Glam dan PT Parama Global Inspira adalah Distributor produk OMG Oh My Glam.
                <br />
                “OMG Oh My Glam”, “OMG”, “Varcos”, “Parama” selanjutnya disebut sebagai “kami”)
              </Info>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
