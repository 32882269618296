import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import PolicyComponent from "../../components/privacy-policy/PrivacyPolicy";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <PolicyComponent />
    </Layout>
  );
};

export default PrivacyPolicy;
